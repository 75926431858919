import {MODE_CALL, MODE_DOWNLOAD} from "../constants";

const Select = ({state, setState}) => {

    return(
        <div className={'selection-block'}>
            <div className="selection">

                <h2>
                    <b>Thank you for your inquiry</b><br />Please choose suitable option
                </h2>

                <button className="btn"
                    onClick={() => setState({...state, mode: MODE_CALL})}
                >I'd like to speak with an expert</button>
                <button className="btn btn__dark"
                        onClick={() => setState({...state, mode: MODE_DOWNLOAD})}
                >I simply want to receive project details</button>
            </div>
        </div>
    )
}

export default Select