import './assets/button.scss'

const LoadingButton = ({loading, label = 'Send', onSubmit, gdpr, setter}) => {

    const submit = () => {
        if(!loading) {
            onSubmit()
        }
    }

    return(
        <>
            <div className={'loading-button'} onClick={submit}>
                {!loading ? label : <div className={'loader'}></div>}
            </div>
            <div className="gdpr">
                <div className="checkbox" onClick={() => setter(!gdpr)}>
                    {gdpr && <svg viewBox="0 0 32 50" xmlns="http://www.w3.org/2000/svg"><path d="M5 16.577l2.194-2.195 5.486 5.484L24.804 7.743 27 9.937l-14.32 14.32z"/></svg>}
                </div>
                <div className="gdpr__text">I have read the <a href="https://w.cliffordrealestates.com/politics/privacy" target="_blank">privacy notice</a></div>
            </div>
        </>

    )
}

export default LoadingButton