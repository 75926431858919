import {useEffect, useState} from "react";
import ModeContainer from "./containers/ModeContainer";
import {getCountryCallingCode} from "react-phone-number-input";
import axios from "axios";
import {MODE_SELECT, MODE_CALL, MODE_DOWNLOAD, MODE_THANK} from "./constants";
import './styles.scss'

const cn = window.data && window.data.country ? window.data.country : 'US'
const md = window.data && window.data.mode ? window.data.mode :  1
const pr = window.data && window.data.pr ? window.data.pr :  ''
const utm_source = window.data && window.data.utm_source ? window.data.utm_source :  ''
const utm_campaign = window.data && window.data.utm_campaign ? window.data.utm_campaign :  ''
const utm_content = window.data && window.data.utm_content ? window.data.utm_content :  ''
const utm_term = window.data && window.data.utm_term ? window.data.utm_term :  ''
const source_url = window.data && window.data.source_url ? window.data.source_url :  ''
const utm_medium = window.data && window.data.utm_medium ? window.data.utm_medium :  ''

const App = () => {

    const [selection, setSelection] = useState()
    const [state, setState] = useState({
        mainHeading: '',
        mode: MODE_SELECT,
        modeReady: false,
        code: '+ 1',
        country: '',
        loading: false
    })

    const [form, setForm] = useState({
        phone: '',
        email: '',
        name: '',
        gdpr: true,
        whatsappChecked: true,
        emailChecked: true,
    })

    function getCookie(cookieName) {
        let cookie = {};
        document.cookie.split(';').forEach(function(el) {
            let [key,value] = el.split('=');
            cookie[key.trim()] = value;
        })
        return cookie[cookieName];
    }

    const onSubmit = () => {

        const fd = new FormData;

        if(form.name.length <= 2) {
            alert('Type your name!')
            return
        }

        if(form.phone.length <= 7) {
            alert('Insert valid phone number!')
            return
        }

        fd.append('utm_source', utm_source);
        fd.append('utm_campaign', utm_campaign);
        fd.append('utm_content', utm_content);
        fd.append('utm_term', utm_term);
        fd.append('utm_medium', utm_medium);
        fd.append('source_url', source_url);

        fd.append('token', getCookie('X-TOKEN'))
        fd.append('name', form.name);
        fd.append('phone', form.phone);
        fd.append('action', selection);
        fd.append('projectSlug', pr);
        fd.append('gdpr', form.gdpr);

        if(selection === MODE_DOWNLOAD) {
            fd.append('email', form.email);
            fd.append('whatsappChecked', form.whatsappChecked);
            fd.append('emailChecked', form.emailChecked);
        }

        setState({...state, loading: true})
        sendRequest(fd)

    }

    const sendRequest = async (fd) => {

        try {
            const res = await axios.post('/', fd)

            if(res.status === 200) {

                if(res.data.status !== undefined && res.data.status === 'error') {
                    alert(res.data.message)
                    setState({...state, loading: false})
                } else {

                    try{
                        window.dataLayer.push({'event': 'submit_form'})
                    } catch (e) {
                        console.error(e)
                    }

                    setState({...state, mode: MODE_THANK, loading: false})
                }

            } else {
                throw new Error('Request error!')
            }

        } catch (e) {
            alert('Something went wrong! Please, reload the page and try again.')
            setState({...state, loading: false})
        }
    }

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    useEffect(() => {

        let code

        if(!state.modeReady) {

            try {
                code = getCountryCallingCode(cn)
                setForm({...form, phone: '+' + code})
            } catch($e) {
                console.error($e)
            }

            setState({...state,
                country: cn,
                mode: md,
                code: '+ ' + code ?? '+ 1',
                modeReady: true
            })

        }
    }, [state, form])

    useEffect(() => {
        switch (state.mode) {
            case MODE_CALL :
                setSelection(MODE_CALL)
                setState({...state, mainHeading: 'Order a call'});
                break;
            case MODE_DOWNLOAD :
                setSelection(MODE_DOWNLOAD)
                setState({...state, mainHeading: 'Project details'});
                break;
            case MODE_THANK :
                setState({...state, mainHeading: 'Your request has been sent!'});
                break;

            // default : setState({...state, mainHeading: 'What do you want to do?'})
        }

    }, [state.mode])

    return (
    <div className={'layout'}>
        <div className="app-body">
            {
                state.mode > MODE_SELECT && state.mode <= MODE_THANK &&

                <div className="heading">

                {md === 1 && state.mode !== MODE_THANK && <div className="back" onClick={() => setState({...state, mode: MODE_SELECT})}>
                        <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.69583 14.7135C8.29027 15.0955 7.63285 15.0955 7.2273 14.7135L0.304168 8.19171C-0.101388 7.80967 -0.101388 7.19036 0.304168 6.80832L7.2273 0.286523C7.63285 -0.0955079 8.29028 -0.0955079 8.69583 0.286523C9.10139 0.668555 9.10139 1.28797 8.69583 1.67L2.50711 7.50001L8.69583 13.3301C9.10139 13.7121 9.10139 14.3314 8.69583 14.7135Z"></path>
                        </svg>
                    </div>
                }

                <h1>{ state.mainHeading } </h1>
            </div>

            }
            <p className={'sysfont'}></p>
            <ModeContainer
                state={state}
                form={form}
                setState={setState}
                setForm={setForm}
                onSubmit={onSubmit}
                defaultCountry={state.country}
            />

        </div>
    </div>
    );
}

export default App;
