import PhoneInput from "react-phone-number-input"
import 'react-phone-number-input/style.css'
import './assets/input.scss'

const PhoneInputGroup = ({value, setter, placeholder, defaultCountry, state}) => {
    return(
        <div className={`phone-input simple-input`}>
            <div className="input-group">
                <h3>Phone number</h3>
                <div className={`input ${state.loading && 'input__disabled'}`}>
                    <PhoneInput
                        placeholder={'+995 123 456 789'}
                        value={ value }
                        onChange={ value => setter(value) }
                        defaultCountry={defaultCountry}
                    />
                    <div className="instruction">
                        <p>Type phone number in international format with "+" and country code</p>

                        <div className={'phone-example numbers'}>
                            <div>Example: {state.code}</div>
                            <div>123 456 789</div>
                        </div>
                    </div>
                </div>
                <div className="error"></div>
            </div>
        </div>
    )
}

export default PhoneInputGroup