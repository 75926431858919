import Callback from "../pages/Callback";
import Download from "../pages/Download";
import Select from "../pages/Select";

import {MODE_SELECT, MODE_CALL, MODE_DOWNLOAD, MODE_THANK} from "../constants";
import Thank from "../pages/Thank";

const ModeContainer = ({state, form, setState, setForm, onSubmit, defaultCountry}) => {

    return(
        <div className={'mode-container'}>
            {state.mode === MODE_SELECT && <Select state={state} form={form} setState={setState} setForm={setForm} />}
            {state.mode === MODE_CALL && <Callback state={state} form={form} setForm={setForm} onSubmit={onSubmit} defaultCountry={defaultCountry} />}
            {state.mode === MODE_DOWNLOAD &&<Download state={state} form={form} setForm={setForm} onSubmit={onSubmit} defaultCountry={defaultCountry} />}
            {state.mode === MODE_THANK && <Thank />}
        </div>
    )

}

export default ModeContainer