import './assets/input.scss'

const SimpleInputGroup = ({value, setter, placeholder, state}) => {
    return(
        <div className={'simple-input'}>
            <div className="input-group">
                <div className={`input ${state.loading && 'input__disabled'}`}>
                    <div className={`placeholder ${value ? 'hidden' : ''}`}>
                        <div>
                            <div>{placeholder}</div>
                        </div>
                    </div>
                    <input type="text" value={value} onChange={event => setter( event.target.value )} />
                </div>
                <div className="error"></div>
            </div>
        </div>
    )
}

export default SimpleInputGroup