import {MODE_CALL, MODE_DOWNLOAD} from "../constants";
import youtube from '../youtube.svg'

const Thank = () => {

    return(
        <div className={'form-block'}>

            <div className="text">
                <p>Thank you!<br />
                We will contact you shortly.</p>
            </div>

            <div className="youtube">
                <a href="https://www.youtube.com/@CliffordPartners" target="_blank">
                    <span className="label">
                        Subscribe to our YouTube channel
                    </span>
                    <span className="icon">
                        <img src={youtube} alt="YouTube" />
                    </span>
                </a>
            </div>
        </div>
    )
}

export default Thank