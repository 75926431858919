import PhoneInputGroup from "../components/PhoneInputGroup";
import SimpleInputGroup from "../components/SimpleInputGroup";
import LoadingButton from "../components/LoadingButton";

const Callback = ({form, setForm, onSubmit, defaultCountry, state}) => {

    return(
        <div className={'callback'}>
            <div className="form-block">
                <PhoneInputGroup
                    value={ form.phone }
                    placeholder={'Phone number in international format'}
                    placeholder2={'+ (country code) phone number'}
                    setter={ value => setForm({ ...form, phone:value }) }
                    defaultCountry={defaultCountry}
                    state={state}
                />

                <SimpleInputGroup
                    placeholder={'Your name'}
                    value={form.name}
                    setter={ value => setForm({ ...form, name:value }) }
                    state={state}
                />
            </div>

            <div className="text">
                <p>Request a consultation. An expert will reach out to you within 15 minutes. This service is completely complimentary.</p>
            </div>

            <LoadingButton onSubmit={onSubmit}
                           label={'Call me back'}
                           loading={state.loading}
                           gdpr={form.gdpr}
                           setter={ value => setForm({ ...form, gdpr:value }) }
            />
        </div>
    )
}

export default Callback