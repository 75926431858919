import PhoneInputGroup from "../components/PhoneInputGroup";
import SimpleInputGroup from "../components/SimpleInputGroup";
import Checkbox from "../components/Checkbox";
import LoadingButton from "../components/LoadingButton";

const Download = ({form, setForm, onSubmit, defaultCountry, state}) => {

    return(
        <div className={'download'}>

            <div className="form-block">
                <PhoneInputGroup
                    value={ form.phone }
                    placeholder={'+ XXX XXX XX XX'}
                    placeholder2={'+ (country code) phone number'}
                    setter={ value => setForm({ ...form, phone:value }) }
                    defaultCountry={defaultCountry}
                    state={state}
                />

                <SimpleInputGroup
                    placeholder={'Your name'}
                    value={form.name}
                    setter={ value => setForm({ ...form, name:value }) }
                    state={state}
                />

                <SimpleInputGroup
                    placeholder={'E-mail'}
                    value={form.email}
                    setter={ value => setForm({ ...form, email:value }) }
                    state={state}
                />
            </div>

            <h2>Receive PDF by:</h2>

            <div className="checkbox-group">
                <Checkbox
                    label={'WhatsApp'}
                    value={form.whatsappChecked}
                    setter={ value => setForm({ ...form, whatsappChecked:value }) }
                />
                <Checkbox
                    label={'E-mail'}
                    value={form.emailChecked}
                    setter={ value => setForm({ ...form, emailChecked:value }) }
                />
            </div>


            <LoadingButton onSubmit={onSubmit}
                           loading={state.loading}
                           gdpr={form.gdpr}
                           setter={ value => setForm({ ...form, gdpr:value }) }
            />
        </div>
    )

}

export default Download